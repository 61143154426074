import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
// import Root from "../../app/root/root"

class Faq extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Hyperlink App" slug="/app/" />
        <div className="container app">
          {/* <Root /> */}
        </div>
      </Layout>
    )
  }
}

export default Faq

export const pageQuery = graphql`
  query {
          site {
          siteMetadata {
          title
        }
        }
    allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC }) {
          edges {
          node {
          excerpt
          fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
      title
      description
    }
  }
}
}
}
`
